import React from 'react';
import { graphql, Link } from 'gatsby';
import styled from 'styled-components';
import Img from 'gatsby-image';
import BackgroundImage from 'gatsby-background-image';
import HighlightedPages from 'gatsby-theme-wordpress/src/components/highlighted-pages';

import Layout from '../../components/layout';
import Content from '../../components/content';
import Wrap from '../../components/wrap';

export const pageQuery = graphql`
  query HomePage($id: String) {
    page: wordpressPage(id: { eq: $id }) {
      yoast_meta {
        name
        content
        property
      }
      yoast_title
      yoast_json_ld {
        wordpress__context
        wordpress__graph {
          wordpress__type
          wordpress__id
          name
          description
          width
          height
          inLanguage
          datePublished
          dateModified
          caption
          isPartOf {
            wordpress__id
          }
          primaryImageOfPage {
            wordpress__id
          }
          url
        }
      }

      image: featured_media {
        caption
        alt_text
        localFile {
          name
          childImageSharp {
            fluid(maxWidth: 1600, cropFocus: ENTROPY) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      title
      content
      ...HighlightedPages
      acf {
        content
        image: background_image {
          alt_text
          localFile {
            name
            childImageSharp {
              fluid(maxWidth: 2400, cropFocus: ENTROPY) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      links: custom {
        linkedPages {
          title
          link
        }
      }
    }
  }
`;

const StyledContent = styled(Content)`
  padding-top: 25px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 50px;
  background-color: #003366;

  text-align: left;
  color: white;

  h2 {
    font-style: italic;
    font-weight: bold;
    color: #88b526;
  }
`;

const Label = styled.div`
  position: absolute;
  background-color: rgba(0, 51, 102, 0.6);
  border-radius: 10px;
  right: 25px;
  top: 40px;
  padding: 20px 15px;

  p {
    margin: 0 0 0 60px;
    text-align: right;
    font-size: 34px;
    line-height: 44px;
    font-style: italic;
    font-weight: bold;
    color: white;
    &:first-line {
      color: #88b526;
    }
  }
`;

const ContentGrid = styled.div`
  padding-bottom: 40px;
  position: relative;
  ${Label} {
    display: none;
  }

  @media screen and (min-width: 990px) {
    display: grid;
    grid-template-columns: 35% 65%;
    ${Label} {
      display: block;
    }
    ${StyledContent} {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }
  }
`;

const StyledBackgroundImage = styled(BackgroundImage)`
  position: relative;
  margin-top: 30px;

  ${Wrap} {
    position: relative;
    padding-top: 90px;
    padding-bottom: 100px;
    @media only screen and (min-width: 768px) {
      display: grid;
      grid-template-columns: 2fr 1fr;
    }
  }

  ${Content} {
    text-align: left;
    color: white;
    h2 {
      color: white;
    }
  }
`;

const Overlay = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgba(48, 48, 48, 0.41);
`;

const StyledLink = styled(Link)`
  background-color: #88b526;
  border-radius: 3px;
  color: white;
  display: block;
  text-align: center;
  margin-bottom: 10px;
  padding: 16px 24px 16px 24px;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  text-decoration: none;

  & + & {
    background-color: #f4e200;
    color: #003366;
  }
`;

const PageTemplate = ({ data }) => {
  const { page } = data;
  return (
    <Layout page={page} noFancyHeader noMainPadding>
      <Wrap noMobilePadding>
        <ContentGrid>
          <StyledContent
            content={page.content}
          />
          <Img
            fluid={page.image?.localFile?.childImageSharp?.fluid}
            alt={page.image?.alt_text}
          />
          <Label dangerouslySetInnerHTML={{ __html: page.image?.caption }} />
        </ContentGrid>
        <HighlightedPages {...page} />
      </Wrap>
      <StyledBackgroundImage
        Tag="section"
        alt={page.acf?.image?.alt_text}
        fluid={page.acf?.image?.localFile?.childImageSharp?.fluid}
      >
        <Overlay />
        <Wrap>
          <Content
            content={page.acf?.content}
          />
          <div>
            {page.links?.linkedPages?.map(({
              title,
              link,
            }) => (
              <StyledLink key={link} to={link}>{title}</StyledLink>
            ))}
          </div>
        </Wrap>
      </StyledBackgroundImage>
    </Layout>
  );
};

export default PageTemplate;
